<template>
    <div id="reportingTemplateParameterPopup">
        <mds-modal
            :title="title"
            :width="'600px'"
            v-model="ModalTemplateParameter"
            aria-labelledby="title-id"
        >
            The selected template requires some additional parameters. Please
            select them below to continue.
            <span v-for="(param, index) in templateParameter" :key="index">
                <mds-form class="template_parameter_form">
                    <span v-if="param.type !== 'boolean'" class="template_parameter_title">{{
                        param.label
                    }}</span>
                    <span
                        class="template_parameter_asterisk"
                        v-if="param.require === 'true' && param.type !== 'boolean'"
                        >&#42;</span
                    >
                    <span class="discription_button"
                        v-if="param.description && param.type !== 'boolean'">
                        <mds-button
                            v-if="param.description.length > 0"
                            :id="index"
                            buttonName="Key"
                            @click="showDiscription(param)"
                            size="small"
                            variation="icon-only"
                            icon="info-circle"
                            type="button"
                        >
                        </mds-button
                    >
                        <mds-popover
                            class="descriptionPopup"
                            v-model="showPopup"
                            v-if="triggerByPopover(param.name) === togglePopover ? true : false"
                            :position="'bottom-right'"
                            :titleHidden="popoverTitle"
                            :triggered-by="triggerBy(index)"
                        >
                            {{ paramterDescription }}
                        </mds-popover>
                    </span>
                    
                    <!-- for type STRING -->
                    <mds-fieldset
                        class="template_parameter_checkbox"
                        v-if="param.type == 'string'"
                    >
                        <mds-input
                            v-model="param.default"
                            :label="blankLabel"
                            maxlength="250"
                            @change="changeInputField()"
                        >
                        </mds-input>
                    </mds-fieldset>

                    <!-- for type NUMBER -->
                    <mds-fieldset
                        class="template_parameter_checkbox"
                        v-if="param.type == 'number'"
                    >
                        <mds-input
                            :label="blankLabel"
                            maxlength="250"
                            v-model="param.default"
                            type="number"
                            @change="changeInputField()"
                        ></mds-input>
                    </mds-fieldset>

                    <!-- for type DATE  -->
                    <mds-fieldset
                        class="template_parameter_checkbox"
                        v-if="param.type == 'date'"
                    >
                        <mds-date-picker
                            :value="new Date(param.default)"
                            :min-max-dates="minMaxDates"
                            :label="blankLabel"
                            :date-format="{locale:'en-gb'}"
                            placeholder="Select a Date"
                            @input="changeInputField($event, param.name)"
                        ></mds-date-picker>
                    </mds-fieldset>

                    <!-- for type Select  -->
                    <mds-fieldset
                        class="template_parameter_checkbox"
                        v-if="param.type == 'select'"
                    >
                        <mds-select
                            :label="blankLabel"
                            @change="changeInputField()"
                            v-model="param.default"
                            placeholder="Select an Option">
                            <option v-for="(item, index) in param.options"
                                :key="index"
                                :selected="param.default === item.label"
                                :value="item.value">
                                    {{ item.label }}
                            </option>
                        </mds-select>
                    </mds-fieldset>

                    <!-- For type BOOLEAN -->
                    <span class="template_boolean" v-if="param.type == 'boolean'">
                        <mds-fieldset class="template_parameter_checkbox">
                            <mds-checkbox
                                :checked="param.default === 'true' || false"
                                :key="index"
                                :value="param.default"
                                :v-model="param.default"
                                :label="param.label"
                                @change="changeInputField($event, param.name)"
                            ></mds-checkbox>
                        </mds-fieldset>
                    </span>
                </mds-form>
            </span>

            <!-- footer -->
            <mds-layout-grid class="footerSection">
                <mds-row>
                    <mds-col>
                        <div class="borderLine"></div>
                    </mds-col>
                </mds-row>
                <mds-row>
                    <mds-col>
                        <mds-button
                            type="button"
                            variation="secondary"
                            @click="cancelButton"
                        >
                            Cancel
                        </mds-button>
                    </mds-col>
                    <mds-col class="saveButton">
                        <span>
                            <mds-button
                                @click="saveButton"
                                variation="primary"
                                :disabled="disableRunButton"
                            >
                                Run
                            </mds-button>
                        </span>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
        </mds-modal>
        
    </div>
</template>

<script>
import MdsModal from "@mds/modal";
import MdsInput from "@mds/input";
import MdsForm from "@mds/form";
import MdsSelect from "@mds/select";
import MdsCheckbox from "@mds/checkbox";
import MdsFieldset from "@mds/fieldset";
import { MdsButton } from "@mds/button";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsPopover from "@mds/popover";
import MdsDatePicker from "@mds/date-picker";
import { dateFormatterMixin } from "../../mixins/date.js";

export default {
    name: "reportingTemplateParameterPopup",
    mixins: [dateFormatterMixin],
    components: {
        MdsModal,
        MdsInput,
        MdsForm,
        MdsCheckbox,
        MdsFieldset,
        MdsButton,
        MdsSelect,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsPopover,
        MdsDatePicker,
    },
    data() {
        return {
            isDate: '',
            // parameters: [
            //     {
            //         name: "Parameter Name 1",
            //         label: "Parameter label 1",
            //         type: "string",
            //         require: false,
            //         default: "template 1",
            //         description: "",
            //     },

            //     {
            //         name: "Parameter Name 2",
            //         label: "Parameter label 2",
            //         type: "number",
            //         require: true,
            //         default: "10",
            //         description: "some content",
            //     },
            //     {
            //         name: "Parameter Name 3",
            //         label: "Parameter label 3",
            //         type: "date",
            //         require: true,
            //         default: "10/07/2021",
            //         description: "some content",
            //     },
            //     {
            //         name: "Select Text",
            //         label: "Select label",
            //         type: "select",
            //         require: false,
            //         options: [
            //             {label: "Test 1", value: "1"},
            //             {label: "test 2", value: "2"}
            //         ],
            //         default: "Test 1",
            //         description: "some content",
            //     },

            //     {
            //         name: "Parameter Name 4",
            //         label: "Parameter label 4",
            //         type: "boolean",
            //         require: true,
            //         default: "true",
            //         description: "some content",
            //     },
            // ],
            blankLabel: "",
            showPopup: false,
            togglePopover: '',
            paramterDescription: "",
            popoverTitle: true,
            parameterArr: [],
            minMaxDates: {
            min: new Date(2000, 0, 1),
            max: new Date(2049, 11, 31),
        },
        };
    },
    props: {
        title: {
            type: String,
            default: "Template Parameters",
        },
        ModalTemplateParameter: {
            type: Boolean,
            default: false,
        },
        templateParameter: {
            type: Array,
            default: () => ([])
        }
    },
    watch: {
        ModalTemplateParameter(showModal) {
            if(showModal) {
                this.onpageLoad();
                this.disableRunButton;
            }
        }
    },
    computed: {
        disableRunButton() {
            const res = this.templateParameter.some(item => {
                if(item.require === 'true' && !item.default) {
                    return true;
                }
            });
            return res;
        },
    },
    methods: {
        triggerBy(param) {
            return param.toString();
        },
        triggerByPopover(param) {
            return param.replace(/ /g, '');
        },
        changeInputField(param, name) {
            const defaultParameter = this.templateParameter;
            this.parameterArr = defaultParameter.reduce((obj, item) => {
                if(item.name === name) {
                    item.default = param.toString()
                }
                if (item.type === 'date') {
                    this.isDate = new Date(item.default);
                    obj[item.name] = this.convertReportingParameterDate(this.isDate);
                } else {
                    obj[item.name] = item.default;
                }
                // const dateVal = new Date(item.default);
                // obj[item.name] = isNaN(item.default) && this.isValidDate(dateVal)
                //     ? this.convertReportingParameterDate(dateVal) : item.default;
                return obj;
            },{});
        },
        isValidDate(param) {
            return param instanceof Date && !isNaN(param);
        },

        onpageLoad() {
            const defaultParameter = this.templateParameter;
            this.parameterArr = defaultParameter.reduce((obj, item) => {
                obj[item.name] = item.default;
                return obj;
            },{});
        },
        saveButton() {
            this.$emit("saveModal", this.parameterArr);
        },
        cancelButton() {
            this.$emit("closeModal");
        },
        showDiscription(val) {
            this.togglePopover = val.name.replace(/ /g, '');
            this.showPopup = !this.showPopup;
            this.paramterDescription = val.description;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.template_parameter_title {
    font-weight: bold;
    margin-top: $mds-space-4-x;
}

.template_parameter_checkbox {
    margin-top: $mds-space-half-x;
}

.discription_button {
    margin: $mds-space-1-x;
}

.footerSection {
    margin-top: $mds-space-1-and-a-half-x;
    margin-bottom: $mds-space-1-and-a-half-x;
}

.borderLine {
    border-top: 1px solid $mds-color-neutral-80;
    margin-bottom: $mds-space-2-x;
}

.saveButton {
    text-align: right;
}

.template_parameter_asterisk {
    color: $mds-feedback-color-error;
    margin-left: $mds-space-1-x;
    margin-top: $mds-space-2-x;
}

.template_parameter_form {
    margin-top: $mds-space-1-x;
}

.descriptionPopup.mds-popover___VueMDS3Demo {
    z-index: 999;
}

.template_boolean {
    margin: $mds-space-1-and-a-half-x 0;
    display: flex;
    .span.mds-checkbox__text___VueMDS3Demo {
        font-weight: bold;
    }
}

#reportingTemplateParameterPopup::v-deep
    .mds-section___VueMDS3Demo
    .mds-section--primary___VueMDS3Demo.mds-section--border-bottom___VueMDS3Demo
    .mds-section__actions___VueMDS3Demo {
    padding-top: 0;
    display: none;
}
</style>