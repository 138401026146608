<template>
    <div id="createReport">
        <div class="create-report">
            <div class="create_report_uppersetion">
            <mds-layout-grid class="create-report">
                <mds-row>
                    <span>
                        <router-link
                            :to="{
                                name: getRouteDetails('/reporting/reports'),
                            }"
                        >
                            Reports
                        </router-link>
                    </span>
                    <span class="create-report-router"> > Run New Report</span>
                </mds-row>
                <mds-row class="create-report-heading">
                    Run New Report
                </mds-row>
            </mds-layout-grid>

            <mds-layout-grid>
                <mds-row class="create-report-selection-section">
                    <!-- Universe Dropdown -->
                    <mds-col :cols="4" class="create-report-type">
                        <mds-form>
                            <mds-select
                                label="Universe"
                                v-model="universe"
                                @change="changeUniverse"
                                :disabled="!isUniverseDisable"
                                placeholder="Select a universe"
                            >
                                <option
                                    v-for="(item, index) in getUniverseData"
                                    :key="index"
                                    :value="JSON.stringify(item)"
                                >
                                    {{ item.listdescription }}
                                </option>
                            </mds-select>
                        </mds-form>
                    </mds-col>

                    <mds-col :col="2" style="margin-top: 30px">
                        <button-component
                            :onClick="universeButton"
                            buttonName="New Universe"
                            buttonVariation="flat"
                            iconName="plus"
                        >
                        </button-component>
                    </mds-col>

                    <!-- Template Dropdown -->
                    <mds-col :cols="4" class="create-report-inputfield">
                        <mds-form>
                            <mds-select
                                label="Template"
                                v-model="template"
                                @change="changeTemplate($event)"
                                placeholder="Select a template"
                            >
                                <option
                                    v-for="(item, index) in getTemplateData"
                                    :key="index"
                                    :value="item.templateid"
                                >
                                    {{ item.templatename }}
                                </option>
                            </mds-select>
                        </mds-form>
                    </mds-col>

                    <mds-col :col="2" style="margin-top: 30px">
                        <button-component
                            :onClick="templateButton"
                            buttonName="New Template"
                            buttonVariation="flat"
                            iconName="plus"
                        >
                        </button-component>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>

            <div>
                <mds-layout-grid>
                    <mds-row>
                        <mds-col :cols="6">
                            <div class="showDataUniTemp">
                                <!-- HTML for dynamic list  -->
                                <template
                                    v-if="this.universeTypes == 'Dynamic'"
                                >
                                    <mds-layout-grid
                                        v-for="(
                                            dynamicuniverse, index
                                        ) in showAddedCriteria"
                                        :key="index"
                                        class="view-universe-list"
                                    >
                                        <mds-row>
                                            <mds-col :cols="4">{{
                                                dynamicuniverse.Title
                                            }}</mds-col>
                                            <mds-col :cols="5">
                                                <span
                                                    v-for="(
                                                        showselectedValue, index
                                                    ) in dynamicuniverse.Value"
                                                    :key="index"
                                                >
                                                    <span
                                                        v-if="
                                                            dynamicuniverse.OperatorCondition !==
                                                            'Between'
                                                        "
                                                        >{{
                                                            showselectedValue.text
                                                        }}</span
                                                    >
                                                    <span
                                                        v-if="
                                                            dynamicuniverse.OperatorCondition !==
                                                                'Between' &&
                                                            index + 1 <
                                                                dynamicuniverse
                                                                    .Value
                                                                    .length
                                                        "
                                                        >,
                                                    </span>
                                                    <span
                                                        v-if="
                                                            dynamicuniverse.OperatorCondition ===
                                                            'Between'
                                                        "
                                                    >
                                                        {{ betweenDateFrom }} To
                                                        {{ betweenDateTo }}
                                                    </span>
                                                </span>
                                            </mds-col>
                                        </mds-row>

                                        <mds-row
                                            v-if="
                                                index + 1 <
                                                showAddedCriteria.length
                                            "
                                            class="
                                                view-universe-list-group-border
                                            "
                                        ></mds-row>
                                    </mds-layout-grid>
                                </template>

                                <!-- HTML for Static list -->
                                <template v-if="this.universeTypes == 'Static'">
                                    <mds-list-group
                                        class="view-universe-list"
                                        v-for="(
                                            staticuniverse, index
                                        ) in selectedEntityNameRow"
                                        :key="index"
                                    >
                                        <span>
                                            {{ staticuniverse.entityname }}
                                        </span>
                                        <div
                                            v-if="
                                                index + 1 <
                                                selectedEntityNameRow.length
                                            "
                                            class="
                                                view-universe-list-group-border
                                            "
                                        ></div>
                                    </mds-list-group>
                                </template>
                            </div>
                        </mds-col>
                        <mds-col :cols="6">
                            <div class="showDataUniTemp">
                                <div
                                    class="template-list"
                                    v-for="(
                                        item, indexList
                                    ) in templateShowdata"
                                    :key="indexList"
                                >
                                    <span>{{ item.name }}</span>
                                </div>
                            </div>
                        </mds-col>
                    </mds-row>
                </mds-layout-grid>
            </div>
            </div>
            <div class="footerSection">
                <mds-layout-grid>
                    <mds-row>
                        <mds-col>
                            <div style="border-top: 1px solid #cccccc"></div>
                        </mds-col>
                    </mds-row>
                    <mds-row class="bottom-section-button">
                        <mds-col>
                            <button-component
                                :onClick="cancelButton"
                                buttonName="Cancel"
                                buttonVariation="secondary"
                            >
                            </button-component>
                        </mds-col>
                        <mds-col style="text-align: right">
                            <span>
                                <button-component
                                    class="bottom-section-run-button"
                                    :onClick="runReportFunction"
                                    buttonName="Run"
                                    buttonVariation="primary"
                                    :isDisabled="!runButtonDisable"
                                >
                                </button-component>
                            </span>
                        </mds-col>
                    </mds-row>
                </mds-layout-grid>
            </div>
        </div>
        <loader-component v-if="showLoader"></loader-component>
        <reportingTemplateParameterPopup
            :ModalTemplateParameter="ModalTemplateParameter"
            :templateParameter="templateParameter"
            @saveModal="runButton"
            @closeModal="closeModal"
        >
        </reportingTemplateParameterPopup>

        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import get from "lodash/get";
import store from "@/store";
import { REPORTING_ACTIONS } from "@/store/modules/reporting/constants";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import ButtonComponent from "../ui_component/ButtonComponent.vue";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import MdsSelect from "@mds/select";
import { mapActions, mapGetters, mapState } from "vuex";
import { saveRunReport } from "../../services/reporting_service.js";
import { MdsListGroup } from "@mds/list-group";
import { getEditTemplateList } from "../../services/reporting_service.js";
import reportingTemplateParameterPopup from "../common_components/ReportingTemplateParameterPopup.vue";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import { universeMixins } from "../../mixins/universeMixins.js";

export default {
    name: "create-report",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsForm,
        ButtonComponent,
        MdsSelect,
        LoaderComponent,
        MdsListGroup,
        // MdsListGroupItem,
        reportingTemplateParameterPopup,
        NotificationComponent,
    },
    beforeCreate() {
        this.$store = store;
    },

    mixins: [universeMixins],

    data() {
        return {
            showLoader: true,
            template: "",
            universe: "",
            getUniverseData: [],
            getTemplateData: [],
            universeStaticShowData: [],
            checkUniverseType: "",
            universeDynamicShowData: [],
            templateShowdata: [],
            ModalTemplateParameter: false,
            templateParameter: [],
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            onClickDisableRun: true,
            universeTypes: "",
            editUniverse: [],
            isUniverseDisable:true,
        };
    },

    computed: {
        ...mapGetters("reporting", [
            "getUniverses",
            "getDynamicDataPoint",
            "getSaveUniverse",
            "getEntity",
            "getEditUniverseList",
        ]),
        ...mapState({
            finalTemplateList(state) {
                return get(
                    state,
                    "reportingModule.templates.templates.templates",
                    []
                );
            },
        }),

        runButtonDisable() {
            if(this.isUniverseDisable == false){
                return true;
            }else{
                const universe = this.universe && JSON.parse(this.universe);
                return this.template && universe.listid && this.onClickDisableRun;
            }
        },
    },

    async mounted() {
        var obj = {
            page: 1,
            pageSize: 0,
        };
        await this.fetchUniversesList(obj);
        await this.$store.dispatch(REPORTING_ACTIONS.FETCH_TEMPLATE_LIST, obj);
        this.showLoader = false;
        this.getUniverseTemplateData();
    },

    methods: {
        ...mapActions("reporting", [
            "fetchUniversesList",
            "fetchDynamicDataPoint",
            "fetchSaveUniverse",
            "fetchEntityList",
            "fetchEditUniverseList",
        ]),

        runReportFunction() {
            if (this.templateParameter != undefined) {
                this.ModalTemplateParameter = !this.ModalTemplateParameter;
            } else {
                this.runButton();
            }
        },

        async runButton(data = {},temp="") {
            this.ModalTemplateParameter = false;
            let response = {};
            this.onClickDisableRun = false;
            if(this.isUniverseDisable === false){
                response = await saveRunReport(
                this.template,
                temp,
                data
            );
            }
            else{
            const universe = JSON.parse(this.universe);
                response = await saveRunReport(
                this.template,
                universe.listid,
                data
            );
            }
            const obj = {
                response,
                ntfnMsg:
                    "Your report is running and will be available for download shortly",
                ntfnType: "success",
                ntfnKey: "success-default",
            };
            // this.onClickDisableRun = true;
            this.notificationResponse(obj);
        },

        async notificationResponse({ response, ntfnMsg, ntfnType, ntfnKey }) {
            if (response.isError == false) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
                setTimeout(() => {
                this.$router.push({
                    name: "Reporting Reports",
                });
            }, 2000);
            } else {
                this.showLoader = false;
                this.selectMultiRowData.length = 0;
                this.notificationKey = "error-default";
                this.notificationMessage = response.data.message;
                this.notificationType = "error";
                this.showNotification = true;
                this.onClickDisableRun = true;
            }
        },

        closeModal() {
            this.ModalTemplateParameter = !this.ModalTemplateParameter;
        },

        //Cancel report function
        cancelButton() {
            this.$router.push({ name: "Reporting Reports" });
        },

        async changeUniverse() {
            this.showAddedCriteria.length = 0;
            this.selectedEntityNameRow.length = 0;
            const selectedVal = JSON.parse(this.universe);
            this.universeTypes = selectedVal.type;
            this.showLoader = true;
            this.editUniverse = {
                listid: selectedVal.listid,
                isPublic: selectedVal.ispublic,
                type: selectedVal.type
            };
            await this.editCheckType();
            
            this.showLoader = false;
        },

        async changeTemplate() {
            let res = this.getTemplateData.filter((item) => {
                return item.templateid == this.template;
            });
            this.isUniverseDisable = res[0].isuniverserequired;
            if(this.isUniverseDisable === false){
                this.selectedEntityNameRow = [];
                this.showAddedCriteria = [];
                this.universe = "";
            }
            this.showLoader = true;
            let tempId = this.template;
            let response = await getEditTemplateList(tempId);
            const dataSetPoint = JSON.parse(response.data.filter);
            this.templateParameter = dataSetPoint.parameters;
            const dataSet =
                (dataSetPoint.data &&
                    dataSetPoint.data.reduce((obj, item) => {
                        const newArr = {
                            name: item.header_name,
                        };
                        obj.push(newArr);
                        return obj;
                    }, [])) ||
                [];
            this.templateShowdata = dataSet;
            this.showLoader = false;
        },

        universeButton() {
            this.$router.push({ name: "Reporting Create Universe" });
        },

        templateButton() {
            this.$router.push({ name: "Reporting Create Template" });
        },

        getUniverseTemplateData() {
            let responseU = this.getUniverses;
            this.getUniverseData = responseU.universelist;
            this.getTemplateData = this.finalTemplateList;
        },


        // Route to Reporting Universe page
        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";

.create-report {
    padding: 16px 16px 0 16px;
}
.create-report-heading {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-2-x;
    height: 36px;
    left: 16px;
    top: 50px;
    font-style: normal;
    font-weight: 250;
    font-size: 32px;
    line-height: 36px;
    color: #1e1e1e;
}
.template-list {
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-top: 8px;
}
.reportPreviewText {
    margin-top: 4px;
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    font-size: 23px;
    line-height: 27px;
    color: #1e1e1e;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.footerSection {
    margin-top: 10px;
    margin-bottom: 10px;
}
.bottom-section-button {
    margin-top: $mds-space-2-and-a-half-x;
}
.bottom-section-run-button {
    margin-left: $mds-space-2-x;
}
.showDataUniTemp {
    margin-top: 15px;
    height: 400px;
    overflow: auto;
}
::v-deep .mds-list-group__link___VueMDS3Demo {
    padding: 0;
}
.template-list {
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 5px;
}

.view-universe-list {
    padding-top: 4px;
    padding-bottom: 4px;
}

.view-universe-list-group-border {
    border-bottom: 1px solid #e5e5e5;
}

.create_report_uppersetion{
    min-height: 80vh;
}
</style>
